<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap align-content-end>
      <v-flex xs12 align-self-center style="
                  font-family: poppinsbold;
                  font-size: 20px;
                ">Event List</v-flex>
      <!-- <v-flex xs3 pa-1 align-self-end class="text-right">
        <v-btn @click="dialog = true"> ADD EVENT + </v-btn>
      </v-flex> -->

      <v-flex xs12 v-if="eventList">
       <v-card v-if="eventList.length>0" elevation="0">
           <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">From Date</th>
                          <th class="text-left">To Date</th>
                          <th class="text-left">Edit</th>
                          <th class="text-left">Delete</th>
                           <th class="text-left">Add Images</th>
                          <!-- <th class="text-left">View</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in eventList" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>{{item.name}}</td>
                          <td>
                            {{ formatDate2(item.fromDate) }}
                          </td>
                          <td> {{ formatDate2(item.toDate) }}</td>
                          <!-- <td> -->
                            <!-- <router-link
                                :to="'/attendanceList?id=' + item._id +'&name=' +item.sessionId.name"
                                style="text-decoration: none"
                              > -->
                           
                            <!-- </router-link> -->
                          <!-- </td> -->
                        
                          <td>
                            <v-icon medium @click="$router.push('/eventEdit?id='+item._id)">mdi-pencil-outline</v-icon>
                          </td>
                          <td>
                            <v-icon medium @click="(dialoge = true), (curId = item._id)">mdi-trash-can-outline</v-icon>
                          </td>
                             <td>
                            <v-btn color="#388E3C" outlined small @click="$router.push('/eventImage?id='+item._id)">Event
                            <v-icon small>mdi-file-image-plus</v-icon>
                         </v-btn>
                         <v-btn color="#2E7D32" outlined small class="mx-1" @click="$router.push('/bannerImage?id='+item._id)">Banner
                            <v-icon small>mdi-file-image-plus</v-icon>
                          </v-btn>
                          <v-btn color="#1B5E20" outlined small @click="$router.push('/galleryImage?id='+item._id)">Gallery
                            <v-icon small>mdi-file-image-plus</v-icon>
                          </v-btn>
                           <v-btn color="#880E4F" class="ml-1"   outlined small @click="$router.push('/galleryVideo?id='+item._id)">Video
                            <v-icon small>mdi-multimedia</v-icon>
                          </v-btn>
                          <!-- <v-btn color="#880E4F" @click="$router.push('/galleryVideoLink?id='+item._id)" outlined small >
                        Video link
                        </v-btn> -->
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
       </v-card>
      </v-flex>
    </v-layout>
   <v-layout wrap justify-center v-if="Pagelength">
                <v-flex xs12 pt-1 text-center align-self-center v-if="Pagelength>0">
                   <v-pagination
                small
                color="#005F32"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
                </v-flex>
              </v-layout>

    <!-- <v-layout wrap> -->
      <v-dialog
        :retain-focus="false"
        v-model="dialog"
        persistent
        max-width="800px"
        :key="dialog"
      >
        <v-card>
          <v-form v-model="addgrade" ref="addgrade">
            <v-card-title>
              <span class="headline">Add Event</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-layout justify-center wrap>
                  <v-flex xs12>
                    <v-text-field
                      class="homeText"
                      outlined
                      dense
                      v-model="name"
                      label=" Name"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fromDate"
                          label="From Date"
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromDate"
                        no-title
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs12>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="todate"
                          label="To Date"
                          outlined
                          dense
                          v-bind="attrs"
                          v-on="on"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        no-title
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
 <v-flex xs12 md12 text-left>
                 <span class="textField1"
                  >Product Description</span
                >
                <div id="app">
                  <vue-editor v-model="shortDescription"></vue-editor>
                </div>
              </v-flex>
              <v-flex xs12 md12 text-left pb-4>
                <span class="textField1"
                  >Product Detailed Description</span
                >
                <div id="app">
                  <vue-editor v-model="description"></vue-editor>
                </div>
              </v-flex>
                  <!-- <v-flex xs12>
                    <v-text-field
                      class="homeText"
                      outlined
                      dense
                      v-model="shortDescription"
                      label="shortDescription"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      class="homeText"
                      outlined
                      dense
                      v-model="description"
                      label="description"
                    ></v-text-field>
                  </v-flex> -->

                  <v-flex xs12>
                    <v-text-field
                      class="homeText"
                      outlined
                      dense
                      v-model="venue"
                      label="venue"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      class="homeText"
                      outlined
                      type="number"
                      dense
                      v-model="contactNumber"
                      label="Contact Number"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12>
                    <v-text-field
                      class="homeText"
                      outlined
                      dense
                      v-model="email"
                      label="Contact E-mail"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      class="homeText"
                      outlined
                      dense
                      v-model="contactAddress"
                      label="Contact Address"
                    ></v-text-field>
                  </v-flex>
                  <!-- image upload -->
                  <v-flex xs12>
                    <v-layout wrap pt-1 align-center>
                      <v-flex xs10 md6 lg12 align-self-center text-center pb-2>
                        <v-card outlined>
                          <v-icon
                            size="25px"
                            id="pro_pic"
                            @click="$refs.files2.click()"
                            >mdi-plus</v-icon
                          >
                          <span
                            style="
                              color: #828282;
                              font-family: montserratLight;
                              font-size: 14px;
                            "
                            >Upload your Image</span
                          >
                          <input
                            v-show="false"
                            accept="image/*"
                            id="file2"
                            ref="files2"
                            multiple="multiple"
                            type="file"
                            @change="uploadImages"
                          />
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    v-for="(item, i) in imageArray"
                    :key="i"
                    pa-1
                  >
                    <v-img :src="item" height="50px" contain>
                      <v-layout wrap>
                        <v-flex text-right pa-0>
                          <v-avatar color="#FF3434" size="15">
                            <v-icon
                              color="#FFF"
                              small
                              @click="removeImageArray(i)"
                            >
                              mdi-close
                            </v-icon>
                          </v-avatar>
                        </v-flex>
                      </v-layout>
                    </v-img>
                  </v-flex>
                  <div class="dottedline"></div>
                  <!-----------------upload banner image------------------>
                  <v-flex xs12>
                    <v-layout wrap pt-1 align-center>
                      <v-flex xs10 md6 lg12 align-self-center text-center pb-2>
                        <v-card outlined>
                          <v-icon
                            size="25px"
                            id="pro_pic3"
                            @click="$refs.files3.click()"
                            >mdi-plus</v-icon
                          >
                          <span
                            style="
                              color: #828282;
                              font-family: montserratLight;
                              font-size: 14px;
                            "
                            >Upload Banner Image</span
                          >
                          <input
                            v-show="false"
                            accept="image/*"
                            id="file3"
                            ref="files3"
                            multiple="multiple"
                            type="file"
                            @change="uploadImages2"
                          />
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    v-for="(item, i) in bannerimageArray"
                    :key="i"
                    pa-1
                  >
                    <v-img :src="item" height="50px" contain>
                      <v-layout wrap>
                        <v-flex text-right pa-0>
                          <v-avatar color="#FF3434" size="15">
                            <v-icon
                              color="#FFF"
                              small
                              @click="removeImageArray(i)"
                            >
                              mdi-close
                            </v-icon>
                          </v-avatar>
                        </v-flex>
                      </v-layout>
                    </v-img>
                  </v-flex>
                   <!-----------------upload gallery image------------------>
                  <v-flex xs12>
                    <v-layout wrap pt-1 align-center>
                      <v-flex xs10 md6 lg12 align-self-center text-center pb-2>
                        <v-card outlined>
                          <v-icon
                            size="25px"
                            id="pro_pic3"
                            @click="$refs.files4.click()"
                            >mdi-plus</v-icon
                          >
                          <span
                            style="
                              color: #828282;
                              font-family: montserratLight;
                              font-size: 14px;
                            "
                            >Upload Gallery Image</span
                          >
                          <input
                            v-show="false"
                            accept="image/*"
                            id="file4"
                            ref="files4"
                            multiple="multiple"
                            type="file"
                            @change="uploadImages3"
                          />
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    v-for="(item, i) in galleryimageArray"
                    :key="i"
                    pa-1
                  >
                    <v-img :src="item" height="50px" contain>
                      <v-layout wrap>
                        <v-flex text-right pa-0>
                          <v-avatar color="#FF3434" size="15">
                            <v-icon
                              color="#FFF"
                              small
                              @click="removeImageArray(i)"
                            >
                              mdi-close
                            </v-icon>
                          </v-avatar>
                        </v-flex>
                      </v-layout>
                    </v-img>
                  </v-flex>
                  <!-- file upload -->
                  <v-flex xs12 pt-6>
                    <v-icon x-large color="#408BFF" @click="$refs.files.click()"
                      >mdi-cloud-upload</v-icon
                    >
                  </v-flex>
                  <v-flex xs12 pb-5>
                    <span class="con4">upload Document</span>
                    &nbsp;
                    <input
                      v-show="false"
                      id="file1"
                      ref="files"
                      type="file"
                      multiple
                      @change="browseCover"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    py-3
                    text-left
                    v-for="(item, i) in documentFile1"
                    :key="i"
                  >
                    <!-- <v-chips> -->
                    <v-chip
                      @click="documentFile1"
                      close
                      dark
                      color="green"
                      style="margin-right: 6px;margin-b"
                    >
                      {{ item.name }}
                    </v-chip>
                    <!-- </v-chips> -->
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!addgrade"
                @click="validateInput()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="cropImageDialog"
        persistent
        :width="
          $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
            ? '100vw'
            : $vuetify.breakpoint.name == 'md'
            ? '80vw'
            : $vuetify.breakpoint.name == 'lg'
            ? '50vw'
            : '40vw'
        "
      >
        <v-card>
          <v-layout wrap justify-center pa-2>
            <v-flex xs12>
              <!-- <ImageCropper
                :image="currentImage"
                :key="currentImage"
                @stepper="imageCropper"
                :cropheight="225"
                :cropwidth="205"
              /> -->
              <ImageCropper
                :image="currentImage"
                :key="currentImage"
                @stepper="imageCropper"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="cropBannerImageDialog"
        persistent
        :width="
          $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
            ? '100vw'
            : $vuetify.breakpoint.name == 'md'
            ? '80vw'
            : $vuetify.breakpoint.name == 'lg'
            ? '50vw'
            : '40vw'
        "
      >
        <v-card>
          <v-layout wrap justify-center pa-2>
            <v-flex xs12>
              <ImageCropper
                :image="currentBannerImage"
                :key="currentBannerImage"
                @stepper="imageCropper2"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
         <v-dialog
        v-model="cropGalleryImageDialog"
        persistent
        :width="
          $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
            ? '100vw'
            : $vuetify.breakpoint.name == 'md'
            ? '80vw'
            : $vuetify.breakpoint.name == 'lg'
            ? '50vw'
            : '40vw'
        "
      >
        <v-card>
          <v-layout wrap justify-center pa-2>
            <v-flex xs12>
              <ImageCropper
                :image="currentGalleryImage"
                :key="currentGalleryImage"
                @stepper="imageCropper3"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
        <v-dialog v-model="deletedialogue" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          pa-0
                          py-0
                          px-0
                          v-on="on"
                          v-bind="attrs"
                          color="#FFFFFF"
                          style="font-size: 15px"
                          >mdi-trash-can-outline</v-icon
                        >
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Photo?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deletedialogue = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="removeImageArray(pageId, index-1)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                       <v-dialog width="400px" v-model="dialoge">
            <v-card width="400px">
              <v-toolbar dark color="grey" dense flat class="body-2">
                <v-toolbar-title> Confirm </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-4 black--text"
                >Are you sure you want to delete this event?
              </v-card-text>
              <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn
                  color="grey"
                  text
                  class="body-2 font-weight-bold"
                  @click="dialoge = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="deleteEvent()"
                  >OK</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
    <!-- </v-layout> -->
  </div>
</template>


<script>
import axios from "axios";
import ImageCropper from "./../Common/imageCropper";
import { VueEditor } from "vue2-editor";

// import store from "./../../store";
export default {
  components: {
    ImageCropper,
     VueEditor,
  },
  data() {
    return {
      msg: "",
      dialog: false,
      addgrade: false,
      documentFile1: [],
      timeout: 5000,
      count: "",
      snackbar: false,
      appLoading: false,
      fromDate: null,
      menu1: false,
      showSnackBar: false,
      todate: null,
      menu2: false,
      venue: null,
      name: null,
      description: null,
      contactNumber: null,
      shortDescription: null,
      email: null,
      eventList:[],
         page: 1,
      Pagelength: 0,
      deletedialogue:false,
      dialoge:false,
      //upload image
      imageArray: [],
      bannerimageArray: [],
      galleryimageArray: [],
      Images1: new FormData(),
      formData: new FormData(),
      Images3: new FormData(),
       cropImageDialog: false,
      cropBannerImageDialog: false,
      cropGalleryImageDialog: false,
      currentImage: null,
      currentBannerImage: null,
      currentGalleryImage: null,
      //upload docs
      docsdata: new FormData(),
      selectedFiles: null,
      selectedFiles2: null,
      selectedFiles3: null,
      image: null,
      contactAddress: null,
     curId:null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    // first image upload
    imageCropper(payload) {
      this.cropImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages(payload.image);
      }
    },
    uploadImages(event) {
      console.log("upload image1");
      if (this.imageArray.length < 10) {
        this.currentImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages(image) {
      this.selectedFiles = image;
      this.Images1.append("image1", this.selectedFiles);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray.push(urls);
        if (this.imageArray) {
          this.image = this.imageArray[0];
        }
      };
      console.log("image1=", this.selectedFiles);
    },
    //banner image
    imageCropper2(payload) {
      this.cropBannerImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages2(payload.image);
      }
    },
    uploadImages2(event) {
      console.log("upload image2");
      if (this.bannerimageArray.length < 10) {
        this.currentBannerImage = URL.createObjectURL(event.target.files[0]);
        this.cropBannerImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages2(image) {
      this.selectedFiles2 = image;
      this.formData.append("bannerImage", this.selectedFiles2);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.bannerimageArray.push(urls);
        if (this.bannerimageArray) {
          this.image = this.bannerimageArray[0];
        }
      };
      console.log("image2=", this.selectedFiles2);
    },
     // gallery image upload
    imageCropper3(payload) {
      this.cropGalleryImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages3(payload.image);
      }
    },
    uploadImages3(event) {
      console.log("upload image3");
      if (this.imageArray.length < 10) {
        this.currentGalleryImage = URL.createObjectURL(event.target.files[0]);
        this.cropGalleryImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    collectImages3(image) {
      this.selectedFiles3 = image;
      this.Images3.append("galleryImage", this.selectedFiles3);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.galleryimageArray.push(urls);
        if (this.galleryimageArray) {
          this.image = this.galleryimageArray[0];
        }
      };
      console.log("image3=", this.selectedFiles3);
    },
    //upload image1
    uploadAllImages(item) {
      this.appLoading = true;
      this.Images1.append("id", item);
      axios({
        method: "POST",
        url: "/media/upload/event/images/new",
        data: this.Images1,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.Images1 = null;
            this.imageArray = [];
            // window.location.reload();
            this.msg = response.data.msg;
            this.showSnackBar = true;
            // this.$router.push("/Seller/Product/Added/" + item);
          } else {
            this.Images1 = new FormData();
            this.imageArray = [];
           this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //upload image2
    uploadAllImages2(item) {
      this.appLoading = true;
      this.formData.append("id", item);
      axios({
        method: "POST",
        url: "/media/upload/event/images/banner",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.formData = null;
            this.bannerimageArray = [];
            // window.location.reload();
          this.msg = response.data.msg;
            this.showSnackBar = true;
            // this.$router.push("/Seller/Product/Added/" + item);
          } else {
            this.formData = new FormData();
            this.bannerimageArray = [];
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     //upload image3
    uploadAllImages3(item) {
      this.appLoading = true;
      this.Images3.append("id", item);
      axios({
        method: "POST",
        url: "/media/upload/event/images/gallery",
        data: this.Images3,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.Images3 = null;
            this.galleryimageArray = [];
            // window.location.reload();
           this.msg = response.data.msg;
            this.showSnackBar = true;
            // this.$router.push("/Seller/Product/Added/" + item);
          } else {
            this.Images3 = new FormData();
            this.galleryimageArray = [];
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //upload document
    browseCover(event) {
      var got = event.target.files;
      if (got.length < 5) {
        for (let i = 0; i < got.length; i++) {
          this.documentFile1.push(got[i]);
        }
        console.log("file isss", this.documentFile1);
      } else {
        this.msg = "Maximum 3 can be uploaded";
        this.showSnackBar = true;
      }
    },
    addDocument() {
      if (this.documentFile1.length > 0) {
        this.appLoading = true;
        axios({
          url: "/policy/add",
          method: "post",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            name: this.title,
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.documentID = response.data.data._id;
            console.log("documentID", this.documentID);
            console.log("documentfile", this.documentFile1);
            if (this.documentID) {
              this.docsupload();
            } else {
              alert("document updated");
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      } else {
        this.msg = "Please Select Document ";
        this.showSnackBar = true;

      }
    },
    docsupload() {
      for (var i = 0; i < this.documentFile1.length; i++) {
        this.docsdata.append("pressKit", this.documentFile1[i]);
      }
      this.docsdata.append("id", this.documentID);

      this.appLoading = true;
      axios({
        url: "/media/upload/event/document",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.docsdata,
      })
        .then((response) => {
          this.appLoading = false;
          this.dialog = false;
          this.documentFile1 = [];
          this.docsdata = new FormData();
          (this.title = null);
          this.getData();
          this.msg = response.data.msg;
          this.showSnackBar = true;
          window.location.reload();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
//
    validateInput() {
      if (!this.name) {
        this.msg = "Please provide name";
        this.showSnackBar = true;
        return;
      } else if (!this.fromDate) {
        this.msg = "Please provide From date";
        this.showSnackBar = true;
        return;
      } else if (!this.todate) {
        this.msg = "Please provide to Date";
        this.showSnackBar = true;
        return;
      } else if (!this.shortDescription) {
        this.msg = "Please Provide short Description";
        this.showSnackBar = true;
        return;
      } else if (!this.description) {
        this.msg = "Please Provide description";
        this.showSnackBar = true;
        return;
      } else if (!this.venue) {
        this.msg = "Please Provide venue";
        this.showSnackBar = true;
        return;
      } else if (!this.contactNumber) {
        this.msg = "Please Provide Contact Number";
        this.showSnackBar = true;
        return;
      } else if (!this.email) {
        this.msg = "Please Provide e-mail";
        this.showSnackBar = true;
        return;
      } else if (!this.contactAddress) {
        this.msg = "Please Provide address";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    add() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/media/add/event",
        data: {
          name: this.name,
          fromDate: this.fromDate,
          toDate: this.todate,
          shortDescription: this.shortDescription,
          description: this.description,
          venue: this.venue,
          contactNumber: this.contactNumber,
          contactEmail: this.email,
          contactAddress: this.contactAddress,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          console.log("list=", response.data.data);
          if (response.data.status == true) {
            this.getData();
            if (response.data.id) {
              console.log("id=", response.data.id);
             
              if (this.imageArray.length > 0) {
                console.log("imgarrray=", this.imageArray);
                this.uploadAllImages(response.data.id);
                this.msg = response.data.msg;
                this.showSnackBar = true;
              }
              if (this.bannerimageArray.length > 0) {
                console.log("bannerimageArray=", this.bannerimageArray);
                this.uploadAllImages2(response.data.id);
                this.msg = response.data.msg;
                this.showSnackBar = true;
              }
               if (this.galleryimageArray.length > 0) {
                console.log("galleryimageArray=", this.galleryimageArray);
                this.uploadAllImages3(response.data.id);
                this.msg = response.data.msg;
                this.showSnackBar = true;
              }
               if (this.documentFile1.length > 0) {
                console.log("hiii");
                this.documentID = response.data.id;
                console.log("documentID", this.documentID);
                console.log("documentfile", this.documentFile1);
                if (this.documentID) {
                  this.docsupload();
                } else {
                  alert("document updated");
                }
              }
            }

            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })

        .catch((err) => {
          this.appLoading = false;

          console.log(err);
        });
    },
     getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/media/admin/event/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          limit: 15,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.eventList = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.snackbar = true;

          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
      formatDate2(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
     //delete button
    deleteEvent() {
      axios({
        url: "/media/admin/event/delete",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.delete = false;
          this.dialoge = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            // this.$router.go(-1)
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>







